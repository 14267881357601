import { Helmet } from "react-helmet-async";
import { CategoryType, metaData } from "../../services/meta.service";

export const CategoryPageMetaData = ({
  categoryName,
}: {
  categoryName: string;
}) => {
  const normalizedCategoryName = categoryName.trim() as CategoryType;
  const title = categoryName && metaData[normalizedCategoryName]?.title;
  const description = categoryName && metaData[categoryName]?.description;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      {categoryName === "surgical-sutures" && (
        <>
          <meta
            property="og:title"
            content="Surgical Sutures - High-Quality Medical Sutures by Nuvo Medsurg"
          />
          <meta
            property="og:description"
            content="Discover Nuvo Medsurg's range of surgical sutures, crafted for precision and reliability. Explore our products designed to meet the needs of healthcare professionals."
          />
          <meta
            property="og:url"
            content="https://www.nuvomedsurg.com/categories/surgical-sutures"
          />
          <meta
            property="og:image"
            content="https://www.nuvomedsurg.com/static/media/novoLogo.49f5a6d9d6b7e6439fce7efae2a8a597.svg"
          />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Nuvo Medsurg" />
        </>
      )}
    </Helmet>
  );
};
