import { useEffect, useState } from "react";
import { useNuvoContext } from "../../../../context/NuvoContext";
import { SingleProductType } from "../../../../types/product.types";
import { getAllProducts } from "../../../../handlers/productHandler";
import TabbedCategories from "./FeaturedSection";



export const Featured = () => {
  const [productsData, setProductsData] = useState<SingleProductType[] | null>(
    null
  );
  const [isLoading, setIsloading] = useState<boolean>(false);
  const { handleError } = useNuvoContext();
  const fetchData = async () => {
    setIsloading(true);
    try {
      const data = await getAllProducts();
      setProductsData(data);
      setIsloading(false);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return <>{productsData && <TabbedCategories data={productsData} />}</>;
};
