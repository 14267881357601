// src/components/Navbar.tsx

import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom"; // For navigation and active link styling
import {
  FaHome,
  FaInfoCircle,
  FaEnvelope,
  FaShoppingBag,
  FaBars,
  FaTimes,
  FaUserAlt,
  FaCartArrowDown,
  FaSignOutAlt,
  FaSearch, // Import Search Icon
  FaPen
} from "react-icons/fa";
import logo from "../../assets/images/novoLogo.svg"; // Ensure the path is correct
import { motion } from "framer-motion";
import { Product, useProductSearch } from "../../hooks/useProductSearch";
import axios from "axios";
import baseUrl from "../../handlers/baseUrl";
import { useNuvoContext } from "../../context/NuvoContext";
import SearchResults from "./SearchResults"; // Import the SearchResults component
import AuthDrawer from "./AuthForm";
import { isAuth } from "../../helpers/authHelper";
import { LoginResponse } from "../../types/user.types";
import { useCart } from "../../context/NuvoCartContext";
import UserButton from "../navbar/UserButton";

interface NavbarProps {
  user: LoginResponse | null;
  setUser: Dispatch<SetStateAction<LoginResponse | null>>;
}
const Navbar: React.FC<NavbarProps> = ({ user, setUser }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false); // State for search bar
  const [searchQuery, setSearchQuery] = useState("");
  const [productData, setProductData] = useState<Product[]>([]);
  const [loginOpen, setLoginOpen] = useState<boolean>(false);
  const [authStatus, setAuthStatus] = useState<boolean>(false);
  const filteredProducts = useProductSearch(productData, searchQuery);
  const { handleError, setRender } = useNuvoContext();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/product/`);
      if (response.status === 200) {
        setProductData(response.data.data);
      }
    } catch (error) {
      handleError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    // @ts-ignore
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleSearch = () => {
    setSearchOpen(!searchOpen);
    if (searchOpen) {
      setSearchQuery(""); // Clear search query when closing
    }
  };

  useEffect(() => {
    const checkAuth = async () => {
      const result = await isAuth();
      setAuthStatus(!!result);
    };

    checkAuth();
  }, []);

  const navigation = [
    { name: "Home", href: "/", icon: <FaHome size={20} /> },
    { name: "About Us", href: "/about-us", icon: <FaInfoCircle size={20} /> },
    { name: "Contact Us", href: "/contact-us", icon: <FaEnvelope size={20} /> },
    { name: "Shop", href: "/categories", icon: <FaShoppingBag size={20} /> },
    {name: "Blog",href: "/blog",icon: <FaPen size={20} />},
  ];
  const { cartItemsCount } = useCart();

  const handleLogout = () => {
    localStorage.removeItem("nuvo_user_cred");
    window.location.reload();
    setRender((prev) => !prev);
    setUser(null);
    navigate("/");
    setIsMobileMenuOpen(false);
  };

  return (
    <nav className="bg-white shadow-md fixed top-0 left-0 right-0 z-50">
      {/* Navbar Container */}
      {loginOpen && <AuthDrawer isOpen={loginOpen} setIsOpen={setLoginOpen} />}

      <div className="mx-auto px-6 lg:px-8">
        <div className="flex justify-between h-20">
          {/* Left: Logo */}
          <div className="flex-shrink-0 flex items-center">
            <NavLink to="/">
              <img className="h-16 w-auto" src={logo} alt="Logo" />
            </NavLink>
          </div>

          {/* Center: Navigation Links (Hidden on Mobile) */}
          <div className="hidden lg:flex lg:items-center lg:space-x-8">
            {/* Navigation Links */}
            {navigation.map((item) => (
              <NavLink
                key={item.name}
                to={item.href}
                className={({ isActive }) =>
                  `flex items-center text-xl font-medium ${
                    isActive
                      ? "text-blue-600 border-b-4 border-blue-600"
                      : "text-gray-700 hover:text-blue-500"
                  } px-3 py-2`
                }
              >
                {item.icon}
                <span className="ml-2">{item.name}</span>
              </NavLink>
            ))}

            {/* Search Icon (Hidden when search is open) */}
            {!searchOpen && (
              <button
                onClick={toggleSearch}
                className="flex items-center text-gray-700 hover:text-blue-500 focus:outline-none ml-4"
                aria-label="Open Search"
              >
                <FaSearch size={20} />
              </button>
            )}
          </div>

          {/* Right: User Profile, Cart Icons, and Search on Desktop */}
          <div className="hidden lg:flex lg:items-center lg:space-x-6 ">
            {/* User Profile */}

            <UserButton authStatus={authStatus} setIsLoginOpen={setLoginOpen} />

            {/* Shopping Cart */}

            {cartItemsCount > 0 ? (
              <div className="flex flex-row gap-5 item-center align-middle justify-center">
                <div className="relative">
                  <div
                    onClick={() => {
                      navigate("/mycart");
                    }}
                    className="relative"
                  >
                    <NavLink
                      to="/cart"
                      className="flex items-center text-xl font-medium text-gray-700 hover:text-blue-500 px-3 py-2 rounded-md relative"
                    >
                      <FaCartArrowDown size={20} />
                      {/* Cart Badge Example (Implement count as needed) */}
                      {/* <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                3
              </span> */}
                    </NavLink>
                    {cartItemsCount > 0 && (
                      <span className="bg-red-500 text-white rounded-full px-2 py-1 text-xs absolute top-0 right-0 translate-x-1/2 -translate-y-1/2">
                        {cartItemsCount}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <FaCartArrowDown size={20} />
              </>
            )}
          </div>

          {/* Mobile menu button and Search Icon */}
          <div className="flex items-center lg:hidden">
            {cartItemsCount > 0 ? (
              <div className="flex flex-row gap-5 item-center align-middle justify-center mr-3">
                <div className="relative">
                  <div
                    onClick={() => {
                      navigate("/mycart");
                    }}
                    className="relative"
                  >
                    <NavLink
                      to="/cart"
                      className="flex items-center text-xl font-medium text-gray-700 hover:text-blue-500 px-3 py-2 rounded-md relative"
                    >
                      <FaCartArrowDown size={20} />
                    </NavLink>
                    {cartItemsCount > 0 && (
                      <span className="bg-red-500 text-white rounded-full px-2 py-1 text-xs absolute top-0 right-0 translate-x-1/2 -translate-y-1/2">
                        {cartItemsCount}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <FaCartArrowDown className="mr-3" size={20} />
              </>
            )}

            {/* Search Icon */}
            {!searchOpen && (
              <button
                onClick={toggleSearch}
                className="flex items-center text-gray-700 hover:text-blue-500 focus:outline-none mr-2"
                aria-label="Open Search"
              >
                <FaSearch size={20} />
              </button>
            )}

            {/* Mobile menu button */}
            <button
              onClick={toggleMobileMenu}
              type="button"
              className="inline-flex items-center justify-center p-3 rounded-md text-gray-700 hover:text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded={isMobileMenuOpen}
            >
              <span className="sr-only">Open main menu</span>
              {isMobileMenuOpen ? (
                <FaTimes className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <FaBars className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Search Bar Overlay */}
      {searchOpen && (
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.3 }}
          className="fixed top-0 left-0 right-0 bg-white shadow-md z-40"
        >
          <div className="w-8/12 mx-auto px-6 lg:px-8">
            <div className="flex items-center justify-between h-20">
              {/* Search Input */}
              <div className="w-full">
                <input
                  type="text"
                  placeholder="Search for products..."
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  autoFocus
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                {/* Search Results */}
                {searchQuery && (
                  <SearchResults
                    results={filteredProducts}
                    onClose={() => {
                      setSearchOpen(false);
                      setSearchQuery("");
                    }}
                    query={""}
                  />
                )}
              </div>

              {/* Close Search Icon */}
              <button
                onClick={() => {
                  setSearchOpen(false);
                  setSearchQuery("");
                }}
                className="ml-4 text-gray-700 hover:text-blue-500 focus:outline-none"
                aria-label="Close Search"
              >
                <FaTimes size={20} />
              </button>
            </div>
          </div>
        </motion.div>
      )}

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="lg:hidden" id="mobile-menu">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {navigation.map((item) => (
              <NavLink
                key={item.name}
                to={item.href}
                className={({ isActive }) =>
                  `flex items-center text-lg font-medium ${
                    isActive
                      ? "text-blue-600 border-l-4 border-blue-600 bg-blue-50"
                      : "text-gray-700 hover:text-blue-500"
                  } px-3 py-2 rounded-md`
                }
                onClick={() => setIsMobileMenuOpen(false)} // Close menu on link click
              >
                {item.icon}
                <span className="ml-2">{item.name}</span>
              </NavLink>
            ))}

            {/* Search Input in Mobile Menu */}
            {searchOpen && (
              <div className="mt-3">
                <input
                  type="text"
                  placeholder="Search for products..."
                  className="w-10/12 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  autoFocus
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                {/* Search Results */}
                {searchQuery && (
                  <SearchResults
                    results={filteredProducts}
                    onClose={() => {
                      setSearchOpen(false);
                      setSearchQuery("");
                    }}
                    query={""}
                  />
                )}
              </div>
            )}

            {/* User Profile and Cart Icons in Mobile Menu */}
            <div className="mt-3 flex space-x-4">
              <NavLink
                to="/dashboard"
                className="flex items-center text-lg font-medium text-gray-700 hover:text-blue-500 px-3 py-2 rounded-md"
                onClick={() => {
                  if (!authStatus) {
                    setLoginOpen(true);
                  } else {
                    navigate("/dashboard");
                    setIsMobileMenuOpen(false);
                  }
                }}
              >
                <FaUserAlt size={20} />
                <span className="ml-2">My Dashboard</span>
              </NavLink>

              <NavLink
                to="/cart"
                className="flex items-center text-lg font-medium text-danger hover:text-red-500 px-3 py-2 rounded-md relative"
                onClick={handleLogout}
              >
                <FaSignOutAlt size={20} />
                <span className="ml-2">Log out</span>
                {/* Cart Badge Example */}
                {/* <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                  3
                </span> */}
              </NavLink>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
