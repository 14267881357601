import axios from "axios";
import  baseUrl  from "./baseUrl";
import { BlogPost, Category, Tag, TagsAndCategories } from "../types/blogs.types";


export const getBlogPosts = async () => {
  const { data } = await axios.get(`${baseUrl}/blog`);
  return data?.data as BlogPost[];
};


export const getBlogPost = async (slug: string) => {
  const { data } = await axios.get(`${baseUrl}/blog/blogSlug/${slug}`);
  return data?.data as BlogPost;
};

export const getBlogCategoriesAndTags = async () => {
  const { data } = await axios.get(`${baseUrl}/blog/tags-and-categories`);
  return data?.data as TagsAndCategories;
};


export const getBlogPostsByCategory = async (categorySlug: string) => {
  const { data } = await axios.get(`${baseUrl}/blog/category/${categorySlug}`);
  return data?.data as BlogPost[];
};

export const getBlogPostsByTag = async (tagSlug: string) => {
  const { data } = await axios.get(`${baseUrl}/blog/tag/${tagSlug}`);
  return data?.data as BlogPost[];
}