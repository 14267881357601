import { Link, useParams } from "react-router-dom";
import {
  getBlogCategoriesAndTags,
  getBlogPost,
} from "../../../handlers/blogHandler";
import { useEffect, useState } from "react";
import { useNuvoContext } from "../../../context/NuvoContext";
import { BlogPost, Category, Tag } from "../../../types/blogs.types";

export default function SingleBlogPage() {
  const { blogSlug } = useParams<{ blogSlug: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [blogData, setBlogData] = useState<BlogPost>();
  const [categories, setCategories] = useState<Category[]>();
  const [tags, setTags] = useState<Tag[]>();
  const { handleError } = useNuvoContext();

  const fetchBlogBySlug = async (slug: string) => {
    try {
      setIsLoading(true);
      const response = await getBlogPost(slug);
      if (response) {
        setBlogData(response);
      }
      setIsLoading(false);
    } catch (error) {
      handleError(error);
    }
  };
  const fetchCategoriesAndTags = async () => {
    try {
      setIsLoading(true);
      const response = await getBlogCategoriesAndTags();
      if (response) {
        setCategories(response.categories);
        setTags(response.tags);
        setIsLoading(false);
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    if (blogSlug) {
      fetchBlogBySlug(blogSlug);
      fetchCategoriesAndTags();
    }
  }, [blogSlug]);

  function formatDateTime(rawDate: string) {
    const date = new Date(rawDate);
    const day = date.getDate().toString().padStart(2, "0");
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`;
  }

  return (
    <div className="min-h-screen p-20">
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row gap-8">
          {blogData ? (
            <div className="flex flex-col gap-10 items-center align-center justify-center w-7/12">
              <p className="text-gray-400">
                {formatDateTime(blogData.createdAt)}
              </p>
              <h1 className="text-3xl font-bold">{blogData.title}</h1>
              <img
                src={blogData.featuredImage}
                alt={blogData.featureImageAt}
                className="w-1/2 h-1/2 object-cover rounded-[8px]"
              />
              <p className="text-left text-gray-400">By {blogData.author}</p>
              {/* FIX_ME Categories and Tags needs to be added */}
              <p className=""></p>
              
              <div
                className="prose"
                dangerouslySetInnerHTML={{
                  __html: blogData.content || "",
                }}
              />
            </div>
          ) : (
            <div>Loading...</div>
          )}
          <div className="hidden md:block border-l border-blue-400" />

          <div className="w-full md:w-1/3">
            <div className="bg-white p-6 rounded-lg shadow-md mb-6">
              <h3 className="text-lg font-semibold text-blue-800 mb-4">
                Categories
              </h3>
              {categories && categories.length === 0 ? (
                <div>Something went wrong. Please try again later</div>
              ) : (
                <div className="flex flex-wrap gap-2">
                  {categories &&
                    categories.map((category) => (
                      <Link
                        to={`/blog/categories/${category.slug}`}
                        key={category._id}
                        className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm font-medium hover:bg-blue-200 transition-colors"
                      >
                        {category.name}
                      </Link>
                    ))}
                </div>
              )}
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-lg font-semibold text-blue-800 mb-4">Tags</h3>
              {tags && tags.length === 0 ? (
                <div>Something went wrong. Please try again later</div>
              ) : (
                <div className="flex flex-wrap gap-2">
                  {tags &&
                    tags.map((tag) => (
                      <Link
                        to={`/blog/tags/${tag.slug}`}
                        key={tag._id}
                        className="px-3 py-1 bg-blue-50 text-blue-700 rounded-full text-sm font-medium hover:bg-blue-100 transition-colors"
                      >
                        #{tag.name}
                      </Link>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
