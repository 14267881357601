import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { sampleBlogPosts } from './sample';
import { BlogPost, Category, Tag } from "../../../types/blogs.types";
import {
  getBlogCategoriesAndTags,
  getBlogPosts,
} from "../../../handlers/blogHandler";
import { useNuvoContext } from "../../../context/NuvoContext";
import Shimmer from "../../Shimmer/Shimmer";
import { BreadCrumbs } from "../../shared/BreadCrumbs";
import { EmptyState } from "../../shared/empty-states/EmptyState";

const BlogLayout = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const postsPerPage = 6;
  const [blogs, setBlogs] = useState<BlogPost[]>([]);
  const [categories, setCategories] = useState<Category[]>();
  const [tags, setTags] = useState<Tag[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const { handleError } = useNuvoContext();
  const fetchBlogs = async () => {
    try {
      setLoading(true);
      const response = await getBlogPosts();
      if (response) {
        setBlogs(response);
        setLoading(false);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const fetchCategoriesAndTags = async () => {
    try {
      setLoading(true);
      const response = await getBlogCategoriesAndTags();
      if (response) {
        setCategories(response.categories);
        setTags(response.tags);
        setLoading(false);
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchBlogs();
    fetchCategoriesAndTags();
  }, []);

  if (loading) {
    return (
      <div className="p-20">
        <div className="w-full flex flex-col md:flex-row gap-5 py-10">
          {/* Left Side Shimmer */}
          <div className="grid grid-cols-2 gap-3 w-8/12">
            {Array(4)
              .fill(0)
              .map((_, index) => (
                <div className="cursor-pointer bg-nuvo-card h-[250px]  p-4 flex flex-col rounded-[8px]">
                  <Shimmer w="100%" h={200} radius="4px 4px 0 0" />
                </div>
              ))}
          </div>
          {/* Right Side Shimmer Container */}
          <div className="flex flex-col gap-2 w-4/12">
            {/* Top Right Shimmer */}
            <div className="cursor-pointer bg-nuvo-card min-h-fit p-4 flex flex-col rounded-[8px] h-[200px]">
              <Shimmer w="100%" h={100} radius="4px 4px 0 0" />
            </div>

            {/* Bottom Right Shimmer */}
            <div className="cursor-pointer bg-nuvo-card min-h-fit p-4 flex flex-col rounded-[8px] h-[200px]">
              <Shimmer w="100%" h={100} radius="4px 4px 0 0" />
            </div>
          </div>
        </div>
      </div>
    );
  }
  // Pagination calculations
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogs && blogs.slice(indexOfFirstPost, indexOfLastPost);
  const totalPages = blogs ? Math.ceil(blogs.length / postsPerPage) : 0;

  // Pagination handlers
  const handlePrevious = () =>
    currentPage > 1 && setCurrentPage(currentPage - 1);
  const handleNext = () =>
    currentPage < totalPages && setCurrentPage(currentPage + 1);

  if (currentPosts && currentPosts.length === 0) {
    return (
      <div className=" h-fit p-20">
        <EmptyState
          title="Feature coming soon"
          description="Hold on, We have not publish any blog yet"
        />
      </div>
    );
  }
  return (
    <div className="min-h-screen p-20">
      <div className="w-full py-5">
        <BreadCrumbs
          breadcrumbs={[
            {
              label: "Home",
              link: "/",
            },
            {
              label: "Blog",
              link: "/blog",
            },
          ]}
        />
      </div>

      <p className="text-3xl md:text-4xl font-bold pb-10 text-center">
        Our Blogs
      </p>

      <div className="flex flex-col md:flex-row gap-8">
        <div className="flex-1">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {currentPosts &&
              currentPosts.map((post) => (
                <div
                  key={post && post?._id}
                  className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow"
                >
                  {post.featuredImage && (
                    <img
                      src={post.featuredImage}
                      alt={post.featureImageAt}
                      className="w-full h-48 object-cover"
                    />
                  )}
                  <div className="p-4">
                    <h2 className="text-xl font-semibold text-blue-800 mb-2">
                      {post.title}
                    </h2>
                    <p className="text-gray-600 text-sm mb-3 line-clamp-3">
                      {post.teaser}
                    </p>
                    <Link
                      to={`/blog/${post.slug}`}
                      className="text-blue-600 hover:text-blue-800 text-sm font-medium underline hover:no-underline"
                    >
                      See more →
                    </Link>
                  </div>
                </div>
              ))}
          </div>

          <div className="mt-8 flex justify-center items-center gap-2">
            <button
              onClick={handlePrevious}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md transition-colors ${
                currentPage === 1
                  ? "bg-gray-200 cursor-not-allowed"
                  : "bg-white hover:bg-blue-100"
              }`}
            >
              Previous
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => setCurrentPage(index + 1)}
                className={`w-8 h-8 rounded-full transition-colors ${
                  currentPage === index + 1
                    ? "bg-blue-600 text-white"
                    : "bg-white hover:bg-blue-100"
                }`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={handleNext}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md transition-colors ${
                currentPage === totalPages
                  ? "bg-gray-200 cursor-not-allowed"
                  : "bg-white hover:bg-blue-100"
              }`}
            >
              Next
            </button>
          </div>
        </div>

        <div className="hidden md:block border-l border-blue-400" />

        <div className="w-full md:w-1/3">
          <div className="bg-white p-6 rounded-lg shadow-md mb-6">
            <h3 className="text-lg font-semibold text-blue-800 mb-4">
              Categories
            </h3>
            {categories && categories.length === 0 ? (
              <div>Something went wrong. Please try again later</div>
            ) : (
              <div className="flex flex-wrap gap-2">
                {categories &&
                  categories.map((category) => (
                    <Link
                      to={`/blog/categories/${category.slug}`}
                      key={category._id}
                      className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm font-medium hover:bg-blue-200 transition-colors"
                    >
                      {category.name}
                    </Link>
                  ))}
              </div>
            )}
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-lg font-semibold text-blue-800 mb-4">Tags</h3>
            {tags && tags.length === 0 ? (
              <div>Something went wrong. Please try again later</div>
            ) : (
              <div className="flex flex-wrap gap-2">
                {tags &&
                  tags.map((tag) => (
                    <Link
                      to={`/blog/tags/${tag.slug}`}
                      key={tag._id}
                      className="px-3 py-1 bg-blue-50 text-blue-700 rounded-full text-sm font-medium hover:bg-blue-100 transition-colors"
                    >
                      #{tag.name}
                    </Link>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogLayout;
